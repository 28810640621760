<template>
  <ErrorContainer>
    <template>A server connection error has occurred.</template>
  </ErrorContainer>
</template>

<script>
import ErrorContainer from '@/views/components/promotion/ErorrContainer.vue';

export default {
  name: 'ApiError',
  components: { ErrorContainer }
};
</script>

<style lang="less" scoped>
@import "~@/less/promotion.less";
[api-error] {}
</style>
